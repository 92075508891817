<template>
  <v-autocomplete
    v-model="selected"
    :search-input.sync="search"
    :loading="loading"
    :items="filteredItems"
    :label="label"
    item-text="full_name"
    item-value="uuid"
    no-filter
    :return-object="true"
    :clearable="clearable"
    :error-messages="errorMessages"
    :disabled="disabled"
  >
    <template slot="selection" slot-scope="data">
      <v-list style="max-width: 450px;" v-if="!onlyName">
        <v-list-item>
          <v-list-item-avatar>
            <user-avatar :user="data.item.user"></user-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{
              data.item.user.first_name + " " + data.item.user.last_name
            }}</v-list-item-title>
            <v-list-item-subtitle class="text--secondary"
              >{{ data.item.birthday | date_to_age }} year /
              {{
                data.item.gender === "MALE" ? "Male" : "Female"
              }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div style="max-width: 450px;" v-else>
        {{ data.item.user.first_name + " " + data.item.user.last_name }}
      </div>
    </template>
    <v-list slot="no-data">
      <v-list-item>
        <v-list-item-title>No result</v-list-item-title>
      </v-list-item>
    </v-list>

    <template slot="item" slot-scope="data">
      <v-list-item-avatar>
        <user-avatar :user="data.item.user"></user-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{
          data.item.user.first_name + " " + data.item.user.last_name
        }}</v-list-item-title>
        <v-list-item-subtitle class="text--secondary"
          >{{ data.item.birthday | date_to_age }} year /
          {{
            data.item.gender === "MALE" ? "Male" : "Female"
          }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import date_to_age from "@/helpers/filters/date_to_age";
import UserAvatar from "../avatars/UserAvatar";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  components: { UserAvatar },
  filters: { date_to_age },
  props: {
    value: {
      required: true
    },
    returnObject: {
      required: false,
      type: Boolean,
      default: false
    },
    clearable: {
      required: false,
      type: Boolean,
      default: true
    },
    errorMessages: {
      required: false,
      default: null
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    filters: {
      required: false,
      type: Object,
      default: () => ({})
    },
    label: {
      required: false,
      type: String,
      default: "Influencer"
    },
    onlyName: {
      required: false,
      type: Boolean,
      default: false
    },
    exclude: {
      required: false,
      type: Array,
      default: () => []
    },
    countryCodes: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    internalValue: null,
    items: [],
    search: "",
    loading: false
  }),
  computed: {
    selected: {
      get() {
        return this.internalValue;
      },
      set(val) {
        if (!val) {
          val = null;
        }
        this.internalValue = val;
        if (val) {
          if (this.returnObject) {
            this.$emit("input", val);
          } else {
            this.$emit("input", val.uuid);
          }
        } else {
          this.$emit("input", null);
        }
      }
    },
    filteredItems() {
      let items = this.cloneDeep(this.items);
      // Check if selected exists
      if (this.internalValue) {
        let exist = items.filter(item => item.uuid === this.internalValue.uuid);
        if (exist.length === 0) {
          items.push(this.internalValue);
        }
      }
      return items.map(item => {
        item.full_name = item.user.full_name;
        return item;
      });
    }
  },
  methods: {
    ...mapActions("core", ["getInfluencersAutocomplete", "getInfluencer"]),
    getItems() {
      if (this.disabled || this.loading) {
        return;
      }
      this.loading = true;
      const filters = this.cloneDeep(this.filters);
      const params = {
        ...filters,
        search: this.search ? this.search : " ",
        limit: 25,
        page: 1
      };

      if (this.countryCodes.length > 0) {
        params.country_codes = this.countryCodes;
      }

      this.getInfluencersAutocomplete(params).then(({ response }) => {
        const arr = [];
        // exclude influencers
        for (let item of response) {
          const match = this.exclude.find(el => {
            return el === item.uuid;
          });
          if (!match) {
            let itemTmp = this.cloneDeep(item);
            delete itemTmp["user"];
            item.user.influencer = itemTmp;
            arr.push(item);
          }
        }
        this.items = arr;
        this.loading = false;
      });
    }
  },
  watch: {
    search: {
      handler: _.debounce(function() {
        this.getItems();
      }, 700)
    },
    exclude() {
      this.getItems();
    },
    disabled() {
      this.getItems();
    },
    filters() {
      this.getItems();
    }
  },
  created() {
    this.getItems();
    // Get object if already has value
    if (this.value !== null && typeof this.value !== "undefined") {
      let selectedId = this.value;
      if (typeof this.value === "object") {
        selectedId = this.value.uuid;
      }
      let params = {
        uuid: selectedId
      };
      this.getInfluencer(params).then(influencer => {
        this.internalValue = influencer;
      });
    }
  }
};
</script>
<style scoped></style>
