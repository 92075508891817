<template>
  <v-dialog v-model="showing" max-width="750px">
    <v-card>
      <v-card-title>
        Refund commission
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="selectedTab">
          <v-tab>New refund</v-tab>
          <v-tab :disabled="childItems.length === 0">Refunds ({{ childItems.length }})</v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item>
            <br/>
            <i>
              Refunds are created for the same date as the parent. Please beware that this may not work properly with commission type "SALE" (Conversion)
            </i>
            <br/>
            <br/>
            Previously refunded: {{ loadingChildren ? '-' : (refundedAmount / 100) }}
            <br/>
            Available to refund: {{ loadingChildren ? '-' : (availableToRefund / 100) }}
            <br/>
            <br/>

            <v-text-field
               v-model="amountToRefund"
               label="Refund amount"
               outlined
               type="number"
            ></v-text-field>
            <v-select
               v-model="refundReason"
               :items="refundReasonItems"
               label="Refund reason"
               outlined
               dense
            ></v-select>
            <v-btn
               v-if="refundReasonText === null"
               text
               color="primary"
               x-small
               @click="refundReasonText = ''"
            >
              Add reason text
            </v-btn>

            <v-textarea
               v-if="refundReasonText !== null"
               v-model="refundReasonText"
               outlined
               label="Refund text"
               placeholder="Public text shown to users"
               persistent-placeholder
               counter="1000"
               maxlength="1000"
            ></v-textarea>
            <v-btn
               v-if="internalNote === null"
               text
               color="primary"
               x-small
               @click="internalNote = ''"
            >
              Add internal note
            </v-btn>
            <v-textarea
               v-if="internalNote !== null"
               v-model="internalNote"
               outlined
               label="Internal note"
               placeholder="Only visible to employees"
               persistent-placeholder
               counter="1000"
               maxlength="1000"
            ></v-textarea>
          </v-tab-item>
          <v-tab-item>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Amount
                  </th>
                  <th class="text-left">
                    Internal note
                  </th>
                  <th class="text-left">
                    Reason
                  </th>
                  <th class="text-left">
                    Reason text
                  </th>
                </tr>
                </thead>
                <tbody>
                  <tr
                     v-for="item in childItems"
                     :key="item.uuid"
                  >
                    <td>{{ item.amount / 100 }}</td>
                    <td>{{ item.internal_note }}</td>
                    <td>{{ getReasonFromConstant(item.refund_reason) }}</td>
                    <td>{{ item.refund_reason_text }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showing = false">
          Close
        </v-btn>
        <v-btn color="primary" :loading="loading" :disabled="isRefundNotAllowed" @click="refundTransaction()">
          Refund {{ amountToRefund }},-
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      required: true
    },
    transaction: {
      required: true
    }
  },
  data: () => ({
    selectedTab: null,
    amount: 0,
    refundReason: 0,
    refundReasonText: null,
    internalNote: null,
    loading: false,
    loadingChildren: true,
    childItems: [],
    refundReasonItems: [
      {text: 'Other', value: 0},
      {text: 'Fraud', value: 1},
    ]
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    amountToRefund: {
      get() {
        return this.amount / 100;
      },
      set(value) {
        this.amount = (value * 100);
      }
    },
    refundedAmount() {
      let amount = this.childItems.reduce((a, b) => a + (b['amount'] || 0), 0);
      return Math.abs(amount);
    },
    availableToRefund() {
      let amount = this.transaction.amount - this.refundedAmount;
      return Math.abs(amount);
    },
    isRefundNotAllowed() {
      return this.loadingChildren || this.amount <= 0 || this.amount > this.availableToRefund;
    }
  },
  watch: {
    transaction: {
      immediate: true,
      handler() {
        if(this.transaction !== null) {
          this.getChildItems();
        }
      }
    }
  },
  methods: {
    ...mapActions('core', ['getTransactionsReport', 'createTransactionRefund']),
    getChildItems() {
      const params = {
        limit: 100,
        page: 1,
        parent_id: this.transaction.uuid,
      };

      this.getTransactionsReport(params).then(response => {
        this.childItems = response.data;
        this.loadingChildren = false;
      });
    },
    refundTransaction() {
      this.loading = true;

      let params = {
        transaction_id: this.transaction.uuid,
        amount: this.amountToRefund,
        refund_reason: this.refundReason,
        refund_reason_text: this.refundReasonText,
        internal_note: this.internalNote,
      };

      this.createTransactionRefund(params).then(() => {
        this.setSnackSuccess('Success');
        this.showing = false;
        this.loading = false;
      }).catch(err => {
        console.error(err);
        this.setSnackError('Error');
        this.loading = false;
      })
    },
    getReasonFromConstant(constant) {
      switch (constant) {
        case 0:
          return 'Other';
        case 1:
          return 'Fraud';
        default:
          return '??';
      }
    }
  }
};
</script>
