<template>
  <v-dialog v-model="showing" max-width="550px">
    <v-card>
      <v-card-title>
        Refund
      </v-card-title>
      <v-card-text>
        <div class="font-weight-bold">Reason</div>
        <div>{{ getReasonFromConstant(transaction.refund_reason) }}</div>
        <br/>

        <div class="font-weight-bold">Reason text</div>
        <div>{{ transaction.refund_reason_text ? transaction.refund_reason_text : '-' }}</div>
        <br/>

        <div class="font-weight-bold">Internal note</div>
        <div>{{ transaction.internal_note ? transaction.internal_note : '-' }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showing = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    value: {
      required: true
    },
    transaction: {
      required: true
    }
  },
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
  },
  methods: {
    getReasonFromConstant(constant) {
      switch (constant) {
        case 0:
          return 'Other';
        case 1:
          return 'Fraud';
        default:
          return '??';
      }
    }
  }
};
</script>
