<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            hide-default-footer
            :items-per-page="50"
        >
            <v-progress-linear
                slot="progress"
                color="primary"
                indeterminate
            ></v-progress-linear>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.uuid">
                        <td>
                            <v-chip
                                color="secondary"
                                label
                                text-color="primary"
                                x-small
                            >
                                {{ item.status }}
                            </v-chip>
                        </td>
                        <td>{{ item.date | date_format("DD-MM-YYYY") }}</td>
                        <td>
                            <v-chip
                                color="secondary"
                                label
                                text-color="primary"
                                x-small
                            >
                                {{ item.type }}
                            </v-chip>
                        </td>
                        <td :class="{'error--text': item.amount < 0}">{{ item.amount / 100 | local_numbers }}</td>
                        <td>
                            <router-link
                                v-if="item.business"
                                :title="item.business.display_name"
                                :to="{
                                    name: 'business',
                                    params: { id: item.business.uuid }
                                }"
                            >
                                {{ item.business.display_name }}
                            </router-link>
                            <span v-else>-</span>
                        </td>
                        <td>
                            <router-link
                                v-if="item.influencer"
                                :title="item.influencer.user.full_name"
                                :to="{
                                    name: 'influencer',
                                    params: { id: item.influencer.uuid }
                                }"
                            >
                                {{ item.influencer.user.full_name }}
                            </router-link>
                            <span v-else>-</span>
                        </td>
                        <td>{{ item.approves_at | date_format("DD-MM-YYYY") }}</td>
                        <td>{{ item.invoices_at | date_format("DD-MM-YYYY") }}</td>
                        <td>
                            <span v-if="item.invoice">{{ item.invoice.number }}</span>
                            <span v-else>-</span>
                        </td>
                        <td>
                          <template v-if="! item.refund">
                            <v-btn
                               x-small
                               outlined
                               color="primary"
                               :disabled="! isFinanceUser"
                               @click="selectedRefundItem = item"
                            >
                              Refund
                            </v-btn>
                            <br/>
                            <v-btn
                               v-if="item.internal_note"
                               x-small
                               outlined
                               color="primary"
                               @click="selectedTransactionItem = item"
                            >
                              View note
                            </v-btn>
                          </template>
                          <template v-else>
                            <v-btn
                               x-small
                               outlined
                               color="primary"
                               @click="selectedRefundNoteItem = item"
                            >
                              View note
                            </v-btn>
                          </template>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        <v-pagination
            v-if="items.length"
            v-model="currPage"
            class="my-2"
            :length="total"
            :total-visible="7"
            @input="handlePageChange"
        ></v-pagination>

      <refund-transaction-dialog
        v-if="selectedRefundItem"
        v-model="showRefundDialog"
        :transaction="selectedRefundItem"
      ></refund-transaction-dialog>

      <refund-transaction-note-dialog
         v-if="selectedRefundNoteItem"
         v-model="showRefundNoteDialog"
         :transaction="selectedRefundNoteItem"
      ></refund-transaction-note-dialog>

      <transaction-note-dialog
        v-if="showTransactionDialog"
        v-model="showTransactionDialog"
        :transaction="selectedTransactionItem"
      ></transaction-note-dialog>
    </div>
</template>

<style scoped>
    td {
        white-space: nowrap;
    }
</style>

<script>
    import local_numbers from "@/helpers/filters/local_numbers";
    import date_format from "@/helpers/filters/date_format";
    import RefundTransactionDialog from "../dialogs/RefundTransactionDialog";
    import RefundTransactionNoteDialog from "../dialogs/RefundTransactionNoteDialog";
    import TransactionNoteDialog from "../dialogs/TransactionNoteDialog";
    import {mapGetters} from "vuex";

    export default {
      components: {TransactionNoteDialog, RefundTransactionNoteDialog, RefundTransactionDialog},
      filters: {
            local_numbers,
            date_format,
        },
        props: {
            items: {
                type: Array,
                required: false,
                default: () => {
                    return [];
                }
            },
            page: {
                type: Number
            },
            total: {
                type: Number
            },
            loading: {
                type: Boolean,
                required: false,
                default: false
            },
            pagination: {
                type: Boolean,
                required: false,
                default: false
            },
            hasLess: {
                type: Boolean,
                required: false,
                default: false
            },
            hasMore: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                headers: [],
                currPage: 1,
                selectedRefundItem: null,
                selectedRefundNoteItem: null,
                selectedTransactionItem: null
            };
        },
        computed: {
          ...mapGetters("core/auth", ["isFinanceUser"]),
          showRefundDialog: {
            get() {
              return this.selectedRefundItem !== null;
            },
            set() {
              this.selectedRefundItem = null;
            }
          },
          showRefundNoteDialog: {
            get() {
              return this.selectedRefundNoteItem !== null;
            },
            set() {
              this.selectedRefundNoteItem = null;
            }
          },
          showTransactionDialog: {
            get() {
              return this.selectedTransactionItem !== null;
            },
            set() {
              this.selectedTransactionItem = null;
            }
          }
        },
        methods: {
            handlePageChange(value) {
                this.$emit("changePage", value);
            }
        },
        watch: {
            items() {
                this.currPage = this.page;
            },
        },
        mounted() {
            this.headers = [
                { text: "Status", value: "status", sortable: false },
                { text: "Date", value: "date", sortable: false },
                { text: "Type", value: "type", sortable: false },
                { text: "Amount", value: "amount", sortable: false },
                { text: "Business", value: "business.display_name", sortable: false },
                { text: "Influencer", value: "influencer.user.full_name", sortable: false },
                { text: "Approves At", value: "approves_at", sortable: false },
                { text: "Invoices At", value: "invoices_at", sortable: false },
                { text: "Invoice Number", value: "invoice.total", sortable: false },
                { text: "", sortable: false },
            ];
        },
    }
</script>
