<template>
    <div>
        <v-card>
            <v-card-title>
                <div>
                    <div class="headline">Commission report</div>
                    <span class="subtitle-1 light-grey"></span>
                </div>
                <v-spacer></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            icon
                            outlined
                            color="primary"
                            @click="
                                filtersTmp = JSON.parse(JSON.stringify(filters));
                                filterDialog = true;
                            "
                        >
                            <v-icon small>fal fa-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filters</span>
                </v-tooltip>
            </v-card-title>
            <div class="date-range-template">
                <date-range-picker
                    :value="{
                        start_date: filters.start_date,
                        end_date: filters.end_date
                    }"
                    @changePeriod="changePeriod"
                    :ranges="ranges"
                ></date-range-picker>
            </div>
            <v-card-text>
                <div class="text-right total-shown" v-if="transactions.length">
                    {{ limit }} out of {{ totalAmount }}
                </div>
                <commission-data-table
                    :items="transactions"
                    :loading="loading"
                    pagination
                    :has-less="page > 1"
                    :has-more="hasMore"
                    :total="total"
                    :page="page"
                    @changePage="_getTransactions($event)"
                ></commission-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="filterDialog" max-width="550px">
            <v-form @submit.prevent="filter()">
                <v-card>
                    <v-card-title class="headline" primary-title>
                        Filter Report
                    </v-card-title>
                    <v-card-text>
                        <v-select
                            v-model="filtersTmp.status"
                            :items="statuses"
                            class="mr-10 filter-select width"
                            item-text="name"
                            item-value="value"
                            clearable
                            label="Status"
                        ></v-select>
                        <v-select
                            v-model="filtersTmp.type"
                            :items="types"
                            class="mr-10 filter-select width"
                            item-text="name"
                            item-value="value"
                            clearable
                            label="Type"
                        ></v-select>
                        <v-text-field
                            v-model="filtersTmp.invoice_number"
                            label="Invoice Number"
                            clearable
                        ></v-text-field>
                        <business-autocomplete
                            v-model="filtersTmp.business_id"
                            :return-object="false"
                        ></business-autocomplete>
                        <influencer-autocomplete
                            v-model="filtersTmp.influencer_id"
                            :return-object="false"
                        ></influencer-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" @click="filter()">
                            Filter
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import search_filters_url from "@/helpers/mixins/search_filters_url";
    import BusinessAutocomplete from "../../autocompletes/BusinessAutocomplete";
    import InfluencerAutocomplete from "../../autocompletes/InfluencerAutocomplete";
    import CommissionDataTable from '../data-tables/CommissionDataTable';
    import DateRangePicker from "@/components/common/filters/DateRangePicker";

    export default {
        components: {
            DateRangePicker,
            CommissionDataTable,
            BusinessAutocomplete,
            InfluencerAutocomplete,
        },
        mixins: [search_filters_url],
        data() {
            const today = new Date();
            const yesterday = this.$moment(today).subtract(1, "days");
            const last7days = this.$moment(today).subtract(6, "days");
            const last30days = this.$moment(today).subtract(29, "days");
            const lastWeekStart = this.$moment(today)
                .subtract(1, "week")
                .isoWeekday(1)
                .startOf("isoweek");
            const lastWeekEnd = this.$moment(today)
                .subtract(1, "week")
                .isoWeekday(1)
                .endOf("isoweek");
            const lastMonthStart = this.$moment(today)
                .subtract(1, "month")
                .startOf("month");
            const lastMonthEnd = this.$moment(today)
                .subtract(1, "month")
                .endOf("month");

            return {
                loading: false,
                totalAmount: null,
                transactions: [],
                filterDialog: false,
                filtersTmp: {},
                filters: {},
                page: 1,
                total: null,
                limit: 50,
                hasMore: true,
                statuses: [
                    { name: "NEW", value: 1},
                    { name: "APPROVED", value: 2 },
                    { name: "INVOICED", value: 3 },
                    { name: "INVOICED PAID", value: 4 },
                    { name: "COMPLETED", value: 5 },
                ],
                types: [
                    { name: "SALE", value: 1 },
                    { name: "CLICK", value: 2 },
                    { name: "IMPRESSION", value: 3 },
                    { name: "CONTENT", value: 4 },
                    { name: "UPFRONT", value: 5 },
                ],
                ranges: {
                    USERDEFINED: [null, null],
                    "TODAY": [today, today],
                    "YESTERDAY": [yesterday._d, yesterday._d],
                    "LAST WEEK": [lastWeekStart._d, lastWeekEnd._d],
                    "LAST MONTH": [lastMonthStart._d, lastMonthEnd._d],
                    "LAST 7 DAYS": [last7days._d, today],
                    "LAST 30 DAYS": [last30days._d, today]
                },
            }
        },
        computed: {
            filtersCount() {
                return Object.keys(this.filters).length;
            }
        },
        watch: {
            filters: {
                handler: function () {
                    // reset
                    this.transactions = [];
                    this.page = 1;
                    this.loading = false;
                    this.hasMore = true;
                    this.setUrlSearchFilters(this.filters);
                    this._getTransactions();
                },
                deep: true,
            },
        },
        methods: {
            ...mapActions('core', ['getTransactionsReport']),
            changePeriod(event) {
                this.filters.start_date = event.start_date;
                this.filters.end_date = event.end_date;
                this.setUrlSearchFilters(this.filters);
                this._getTransactions(1);
            },
            _getTransactions(page = 1) {
                const STATUSES = [
                    "NEW",
                    "APPROVED",
                    "INVOICED",
                    "INVOICED PAID",
                    "COMPLETED",
                ];

                const TYPES = [
                    "SALE",
                    "CLICK",
                    "IMPRESSION",
                    "CONTENT",
                    "UPFRONT",
                    "REFERRAL",
                ];

                if (this.loading) {
                    return;
                }
                
                this.page = page;
                this.transactions = [];
                this.loading = true;

                const filters = this.cloneDeep(this.filters);

                const params = {
                    ...filters,
                    limit: this.limit,
                    page: this.page,
                    order_by: "date",
                    order: "desc"
                };

                this.getTransactionsReport(params).then(response => {
                    response.data.forEach(item => {
                        const exists = this.transactions.filter(itemTmp => itemTmp.uuid === item.uuid);

                        if (exists.length === 0) {
                            item.status = STATUSES[item.status-1];
                            item.type = TYPES[item.type-1];
                            this.transactions.push(item);
                        }
                    });

                    // If no more items
                    if (response.data.length !== params.limit) {
                        this.hasMore = false;
                    }

                    this.loading = false;
                    this.total = response.lastPage;
                    this.totalAmount = response.total;
                });
            },
            filter() {
                this.filters = this.cloneDeep(this.filtersTmp);
                this.filterDialog = false;
            },
        },
        created() {
            this.filters = this.getUrlSearchFilters();
        }
    }
</script>